<template lang="pug">
    section#mosaico
        ul.mosaico
            li(v-for="item, index in items", :class="`_0${index+1}`")
                img(:src="item.image")
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "section-mosaico",
    props,
    data() {
        return {
            items: [],
        }
    },
    mounted() {
		this.items = this.contents.content.map(item => ({
			...item,
			image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
		}))
	}
}
</script>

<style lang="stylus" scoped src="./MosaicoBeneficios.styl"></style>